import { useState } from "react";
import "./index.css";

function Footer() {
  return (
    <>
      <footer className="footer-bg show-web-interface ">
        <div className="">
          <div
            className="py-2"
            style={{ background: "#222222", color: "white" }}
          >
            <div className="d-flex align-items-center px-5 py-1">
              <div className="pr-5">Contact EDD</div>
              <div className="pr-5">Condition of Use </div>
              <div className="pr-5">Privacy Policy</div>
              <div>Accesibility</div>
            </div>
          </div>
          <div
            className="py-2"
            style={{ background: "#efefef", color: "#222222" }}
          >
            <div className="d-flex align-items-center px-5 py-1">
              <div>Copyright &copy; 2024 State of California</div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer-bg show-mobile-interface ">
        <div className="">
          <div
            className="py-2"
            style={{ background: "#222222", color: "white" }}
          >
            <div className="text-center">Contact EDD</div>

            <div className="d-flex align-items-center px-5 py-1">
              <div className="pr-2" style={{fontSize:'11px'}}>Privacy Policy |</div>
              <div className="pr-2"  style={{fontSize:'11px'}}>Condition of Use | </div>

              <div  style={{fontSize:'11px'}}>Accesibility</div>
            </div>
          </div>
          <div
            className="py-2 text-center"
            style={{ background: "#efefef", color: "#222222" }}
          >
            <div className="d-flex justify-content-center align-items-center px-5 py-1">
              <div>Copyright &copy; 2025 State of California</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
